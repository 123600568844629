<template>


    <div class="sy1" id="main">

        <div class="sy9">
            <div class="sy2">
                <div class="sy3">
                    <div style="font-size: 38px;color: #fff;">
                        虚拟商城
                    </div>
                    <div>
                        数字权益商品集成式服务应用，提供模块化虚拟商 <br>
                        城搭建服务，帮助企业自助配置商品、定价 、UI<br>
                        模板及支付方式，快速创建属于自己的虚拟商城。
                    </div>

                </div>
            </div>

        </div>

        <div class="sy4">
            <div class="sy5">
                <span>
                    <animate-number from="0" :to="900" duration="2000"></animate-number>万<sup>+</sup> </span>
                <div>
                    忠实用户
                </div>
            </div>
            <div class="sy5">
                <span><animate-number from="0" :to="2" duration="500"></animate-number>年<sup>+</sup> </span>
                <div>
                    锤炼发展
                </div>
            </div>
            <div class="sy5">
                <span><animate-number from="0" :to="1" duration="100"></animate-number>亿<sup>+</sup> </span>
                <div>
                    年交易额
                </div>
            </div>
            <div class="sy5">
                <span><animate-number from="0" :to="1000" duration="2000"></animate-number>家<sup>+</sup> </span>
                <div>
                    合作客户
                </div>
            </div>
        </div>

        <div class="sy6">

            <div class="sy7">
                <div class="sy8">
                    <span>
                        产品服务
                    </span>
                    <div>
                        整合虚拟类商品上下游产业生态，从量级供应、技术服务、产品运营、营销推 <br> 广等维度为企业提供一站式解决方案
                    </div>
                </div>

                <div class="sy12">
                    <div class="sy10 syim1">
                        <div style="width: 100%;text-align: center;position: absolute;bottom: 45px;">
                            <el-button type="" class="sy11" @click="routpush('virt')">查看更多</el-button>
                        </div>
                    </div>
                    <!-- <div class="sy10 syim2">
                        <div style="width: 100%;text-align: center;position: absolute;bottom: 45px;">
                            <el-button type="" class="sy11">查看更多</el-button>
                        </div>
                    </div> -->
                    <div class="sy10 syim3">
                        <div style="width: 100%;text-align: center;position: absolute;bottom: 45px;">
                            <el-button type="" class="sy11"  @click="routpush('mark')">查看更多</el-button>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="sy13">
            <div class="sy14">
                合作伙伴
            </div>

            <div class="sy15">
                <el-carousel height="300px" trigger="click">
                    <el-carousel-item v-for="item in listUrl" :key="item.url"
                        style="display: flex;justify-content: center;">
                        <!-- <h3 class="small">{{ item.url }}</h3> -->
                        <img :src="item.url" alt="" srcset="">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="all1">

            <div class="all2">

                <div class="all3">

                    <div>
                        拔刀科技
                    </div>

                    <div class="add4">
                        合作 ▪ 分享 ▪ 共赢
                    </div>
                </div>

                <div class="all5">
                    <div class="all6">
                        <!-- 周口拔刀网络科技有限公司 -->
                        周口拔刀网络科技有限公司
                    </div>
                    <div class="add4" style="display: flex;">
                        <div>
                            <i class="el-icon-office-building"></i>
                        </div>
                        <div>
                            河南省郑州市金水区金水路305号1号楼2单元 <br>25层西户
                        </div>
                    </div>
                    <div class="add4">
                        <i class="el-icon-phone-outline"></i>
                        <span>
                            15136250351
                        </span>
                    </div>
                </div>
            </div>

            <div class="all17">
                Copyright © 周口拔刀网络科技有限公司 <span @click="put">豫ICP备2023002840号-1</span>
            </div>
        </div>


    </div>


</template>
<script>

export default {
    name: 'introduce',
    components: {
    },

    data() {
        return {
            listUrl: [
                {
                    url: require("@/assets/q2.png")
                },
                {
                    url: require("@/assets/q1.png")
                },
            ]

        }
    },
    mounted() {
        document.getElementById("main").scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    },

    methods: {
        put() {
            window.open('https://beian.miit.gov.cn/')
        },
        routpush(val) {
            console.log(val, "vlavlalv");
            this.$router.push(val)
        },
    }
}

</script>
<style lang="less" scoped>
.sy1 {
    width: 100%;
    height: 100%;

}

.sy9 {
    width: 100%;
    background: #03101B;
}

.sy2 {
    margin: 0 auto;
    width: 1484px;
    height: 472px;
    background-image: url(../assets/banner.png);
    // background-repeat: repeat-x;
    // background-size: 100% 100%;
    background-repeat: no-repeat;
}

.sy3 {
    font-size: 22px;
    color: #e2dddd;
    font-weight: 600;
    padding-left: 12%;
    padding-top: 9%;
}

.sy4 {
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 50px 260px;
}

.sy5 {
    width: 20%;

    >span {
        font-size: 30px;
        font-weight: 600;
        color: #064CCD;

        >sup {
            font-weight: 100;
        }
    }

    >div {
        color: #808082;
        font-size: 15px;
    }
}

.sy6 {
    width: 100%;
    height: 100%;
    background: #F1F1F8;
}

.sy7 {
    max-width: 1200px;
    margin: 0 auto;
}

.sy8 {
    text-align: center;
    color: #000000;
    padding-top: 70px;

    >span {
        font-size: 36px;
        font-weight: 800;
        padding-bottom: 18px;
        display: inline-block;
    }

    >div {
        color: #8D8B8B;
        line-height: 26px;
    }
}

.sy10 {
    width: 350px;
    height: 544px;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 20px;
    margin-top: 12px;
    position: relative;

}

.syim1 {
    background-image: url(../assets/c1.png);

}

// .syim2{
//     background-image: url(../assets/c2.png);

// }
.syim3 {
    background-image: url(../assets/c3.png);

}

.sy11 {
    text-align: center;
    background: #08A28E;
    width: 211px;
    font-size: 16px;
    color: #fff;
}

.sy12 {
    margin-top: 35px;
    display: flex;
    // margin: 0px 25px;
    justify-content: space-around;
}

.sy13 {
    width: 100%;
    margin-top: 80px;

}

.sy14 {
    width: 100%;
    text-align: center;
    font-weight: 800;
    font-size: 37px;
    color: #000;
    padding-bottom: 40px;
}

.sy15 {
    width: 1350px;
    margin: 0px auto;
}

.all1 {
    margin-top: 60px;
    background: #262431;
    height: 300px;
}

.all2 {
    width: 1000px;
    height: 86%;
    color: #F1F1F8;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all3 {
    font-size: 18px;
    font-weight: 600;

}

.add4 {
    margin-top: 16px;

    i {
        padding-right: 10px;
    }

    span {
        font-size: 15px;
    }
}

.all5 {}

.all6 {
    font-size: 20px;
    font-weight: 600;
}

.all17 {
    font-size: 13px;
    color: #f1f1f8;
    text-align: center;
    opacity: 0.6;

    span {
        cursor: pointer;
    }
}
</style>
